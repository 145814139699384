import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Icons from '../constants/icons'
import { ImageTextButton } from '../views/ImageTextButton'
import NPButton from '../views/NPButton'
import './Navbar.css'


function Navbar() {
    const [hamburger, setHamburger] = useState(false)
    const [button, setButton] = useState(true);

    const toggleHamburger = () => {
        setHamburger(!hamburger)
    };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    const openDriveWithUs = () => {
        window.open('https://navipickups.com/drivers/')
    };



    return (
        <>
            <nav className='navbar'>
                <div className="navbar-container">
                    <Link to="/">
                        <img src='https://homepage-files-media.s3.ca-central-1.amazonaws.com/navbar_images/NaviPickups_RGB_horizontal_midnight+1.png' className="navbar-logo" />
                    </Link>

                    <div className='navbar-btn-div'>

                        <ImageTextButton 
                            style={{}} 
                            className="navbar-drive" 
                            text={"Drive with us"} 
                            icon='https://homepage-files-media.s3.amazonaws.com/navbar_images/drive_with_us.png' 
                            onClick={openDriveWithUs} 
                        />

                        <ImageTextButton 
                            style={{}} 
                            className="navbar-drive" 
                            text={"Sign in"} 
                            icon='https://homepage-files-media.s3.amazonaws.com/navbar_images/icon_login_navbar.png' 
                            onClick={toggleHamburger} 
                        />

                        <NPButton className='request-btn' variant="contained" color="primary">Request a pickup</NPButton>

                        <div onClick={toggleHamburger} className="navbar-hamburger">
                            <img 
                                className="navbar-hamburger-icon" 
                                src='https://homepage-files-media.s3.ca-central-1.amazonaws.com/navbar_images/Vector.png'
                            />
                        </div>

                    </div>

                </div>
            </nav>
        </>
    )
}

export default Navbar
import React from 'react';
import './ImageTextButton.css';
import { Link } from 'react-router-dom';

export const ImageTextButton = ({
    children,
    type,
    className,
    icon,
    text,
    onClick
}) => {
    const _onClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={className ?? {}}>
            <div onClick={_onClick} className="btn--primary">
                <img src={icon} className="icon" />
                {text}
            </div>
        </div>
    );
};
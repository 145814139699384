import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import Navbar from './components/navbar/Navbar';
import './components/screens';
import Home from './components/screens/home/Home';
import { Colors } from './components/constants/colors'


const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: Colors.blue,
      darker: Colors.blueDark,
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<Home/>} />
          {/* <Route path="/about" component={About} />
          <Route path="/shop" component={Shop} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;

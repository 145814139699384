
import home_bg2 from "../../assets/bgimages/NP_Home_bg2.png";
import screen1 from "../../assets/screens/screen1.png";
import screen2 from "../../assets/screens/screen2.png";
import screen3 from "../../assets/screens/screen3.png";
import google_play from "../../assets/google_play.png";
import apple_store from "../../assets/apple_store.png";
import ic_fb from "../../assets/ic_fb.png";
import ic_google from "../../assets/ic_google.png";
import ic_email from "../../assets/ic_email.png";
import ic_logo from "../../assets/ic_logo.png";
import ic_yt from "../../assets/ic_yt.png";
import ic_insta from "../../assets/ic_insta.png";
import carousel_item1 from "../../assets/carousel/carousel_item1.jpg";
import carousel_item2 from "../../assets/carousel/carousel_item2.jpg";
import carousel_item3 from "../../assets/carousel/carousel_item3.jpg";
import carousel_blue_bg from "../../assets/carousel/carousel_blue_bg.png";
import carousel_map_bg from "../../assets/carousel/carousel_map_bg.png";
import carousel_rect_1 from "../../assets/carousel/carousel_rect_1.png";
import carousel_rect_2 from "../../assets/carousel/carousel_rect_2.png";

export default {
    home_bg2,
    screen1,
    screen2,
    screen3,
    google_play,
    apple_store,
    ic_fb,
    ic_google,
    ic_email,
    ic_logo,
    ic_yt,
    ic_insta,
    carousel_item1,
    carousel_item2,
    carousel_item3,
    carousel_blue_bg,
    carousel_map_bg,
    carousel_rect_1,
    carousel_rect_2,
}
import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system';
// import styled, { css } from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Icons from '../../constants/icons'
import Navbar from '../../navbar/Navbar'
import './Home.css'
import { Colors } from '../../constants/colors'
import video from '../../../assets/video.mp4'
import NPButton from '../../views/NPButton';


const NPButton1 = styled(NPButton)`
    border-radius: 0px;
    background-color: #3660AD;
    width: 60%;
    height: 80px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 34px;
    font-weight: 700;
`;


function Home() {
    const [hamburger, setHamburger] = useState(false)
    const [button, setButton] = useState(true);
    const [youtubeID] = useState('0IccFW9mPlY')

    const toggleHamburger = () => {
        setHamburger(!hamburger)
    };

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);


    const screenshots = [
        {
            index: 1,
            icon: Icons.screen1,
            text: "Select the right vehicle \nfor your pickup"
        }, 
        {
            index: 2,
            icon: Icons.screen2,
            text: "Tell us a bit more \nabout your items"
        }, 
        {
            index: 3,
            icon: Icons.screen3,
            text: "Track your driver’s location \non their way to the pickup"
        }
    ]

    const NewlineText = (props) => {
        const text = props.text;
        return text.split('\n').map(str => <div className={props.className}>{str}</div>);
    };

      
    const presentScreenshot = (item) => {
        return (
            <div className='screenshot-item-div'>

                <div className='screenshot-index'>{item.index}</div>

                <img src={item.icon} className="screenshot-img" />

                <NewlineText className='screenshot-text' text={item.text} />

            </div>
        );
    };

    const openDrive = () => {
        window.open("https://navipickups.com/drivers")
    };

    const openPartner = () => {
        window.open("https://navipickups.com/drivers")
    };
    
    const carouselItems = [
        {
            image: Icons.carousel_item1
        },
        {
            image: Icons.carousel_item2,
            btnTitle: 'Drive with us',
            btnAction: openDrive
        },
        {
            image: Icons.carousel_item3,
            btnTitle: 'Partner with us',
            btnAction: openPartner
        }
    ];

    const presentCarouselItem = (item, index) => {
        return (
            <div className='carousel-item-div'>
                <img src={item.image} />
                {
                    index > 0 && <NPButton className='carousel-item-button' variant="contained" color="primary" onClick={item.btnAction}>{item.btnTitle}</NPButton>
                }
            </div>
        );
    };
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
    };
  
  
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
    };

    return (
        <>
            {/* First page + Nav bar */}
            <nav>
                <div style={{
                    backgroundImage: `url(https://homepage-files-media.s3.ca-central-1.amazonaws.com/navbar_images/NP_Hero_PartnerWithUs.png)`, 
                    height:'105vh', 
                    margin:'-10px', 
                    backgroundSize: 'cover', 
                    backgroundRepeat: 'no-repeat',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Navbar />
                    
                    <div className='input-top-main-div'>

                        <div className='input-top-div'>
                            <img src='https://homepage-files-media.s3.ca-central-1.amazonaws.com/navbar_images/icon-login-navbar-input.png' className='request-pickup-icon' />
                            <div className='request-pickup-pipe'></div>
                            <input className='request-pickup-input' placeholder='Enter your location' />
                        </div>

                        <NPButton1 className='input-bottom-div' variant="contained" color="primary">Request a pickup</NPButton1>

                    </div>

                </div>
            </nav>


            {/* Screenshots */}
            <nav>
                <div style={{ 
                    backgroundImage: `url(${Icons.home_bg2})`, 
                    height:'100vh', 
                    margin:'-10px', 
                    backgroundRepeat: 'no-repeat',
                }} className='screenshot-main-div'>
                    <h3 className='screenshot-h3'>
                        Quick and easy 3-step delivery
                    </h3>

                    <div className='screenshot-div'>
                        {screenshots?.map(presentScreenshot)}
                    </div>
                </div>
            </nav>


            {/* App Store links */}
            <nav>
                <div style={{
                    backgroundColor: Colors.blueDark,
                    borderRadius: 50,
                }} className='app-store-div'>
                    <h1 className='app-store-h1'>
                        Download our app to get <br/>
                        "Your goods. Your way."
                    </h1>

                    <h2 className='app-store-h2'>
                        Request a pickup now through our website or <br/>
                        download our app for Android and iPhone.
                    </h2>

                    <div className="app-store-btn-div">

                        <NPButton className='request-btn' variant="contained" color="primary">Request a pickup</NPButton>

                        <a href="https://play.google.com/store/apps/details?id=com.navipickups" className="app-store-btn">
                            <img src={Icons.google_play} className="app-store-icon" />
                        </a>
                        <a href="https://apps.apple.com/ca/app/navi-pickups/id1587029478" className="app-store-btn">
                            <img src={Icons.apple_store} className="app-store-icon" />
                        </a>
                    </div>
                </div>
            </nav>


            {/* Video */}
            <nav style={{ marginLeft:'-10px', marginRight:'-10px', marginBottom: '-10px' }}>
                {/* <iframe className='video'
                    title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
                </iframe> */}
                {/* <YouTube videoId={youtubeID} opts={opts} onReady={_onReady} hover={{}}/> */}
                <video id="background-video" className='video' autoPlay loop muted src={video} type="video/mp4">
                </video>
                {/* <div class="elementor-background-video-container">
					<iframe class="elementor-background-video-embed" 
                        allowfullscreen="1" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        title="YouTube video player" 
                        src="https://www.youtube.com/embed/0IccFW9mPlY?controls=0&amp;rel=0&amp;playsinline=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fnavipickups.com&amp;widgetid=1" 
                        id="widget2" style="width: 1739px; height: 978.188px;" width="640" height="360" frameborder="0">
                            
                        </iframe>
				</div> */}
            </nav>



            {/* Carousel */}
            <nav className='carousel-container'>
                <Carousel 
                    autoPlay={true} 
                    infiniteLoop={true} 
                    showThumbs={false} 
                    showArrows={false}
                    showStatus={false}>
                    {
                        carouselItems?.map(presentCarouselItem)
                    }
                </Carousel>
            </nav>



            {/* Footer */}
            <div className='footer-container'>
                <div className='footer-rect'>
                    <img src={Icons.ic_logo} className="footer-logo" />

                    <div className='footer-social-icons-div'>

                        <a href="https://www.facebook.com/navipickups" className="footer-social-btn">
                            <img src={Icons.ic_fb} className="footer-social-icon" />
                        </a>
                        <a href="https://www.instagram.com/navipickups/" className="footer-social-btn">
                            <img src={Icons.ic_insta} className="footer-social-icon" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCehEf1B7LELwCK47Lc1xgPQ" className="footer-social-btn">
                            <img src={Icons.ic_yt} className="footer-social-icon" />
                        </a>
                        <a href="mailto:info@navipickups.com" className="footer-social-btn">
                            <img src={Icons.ic_email} className="footer-social-icon" />
                        </a>
                    </div>
                </div>
                <div className='footer-rect2'>

                    <h1 className='footer-link-title'>
                        COMPANY
                    </h1>

                    <a href="https://navipickups.com/about-us/" className="footer-link-a">
                        <h3 className='footer-link'>
                            About us
                        </h3>
                    </a>

                    <a href="https://navipickups.com/services/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Services
                        </h3>
                    </a>

                    <a href="https://navipickups.com/insurance/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Cargo protection
                        </h3>
                    </a>

                    <a href="https://navipickups.com/privacy/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Privacy
                        </h3>
                    </a>

                </div>
                <div className='footer-rect3'>

                    <h1 className='footer-link-title'>
                        QUESTIONS
                    </h1>

                    <a href="https://navipickups.com/faq/" className="footer-link-a">
                        <h3 className='footer-link'>
                            FAQs
                        </h3>
                    </a>

                    <a href="https://navipickups.com/terms-of-use/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Terms of service
                        </h3>
                    </a>

                    <a href="https://navipickups.com/safety/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Safety
                        </h3>
                    </a>

                    <a href="https://navipickups.com/contact/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Contact
                        </h3>
                    </a>

                </div>
                <div className='footer-rect4'>

                    <h1 className='footer-link-title'>
                        JOIN
                    </h1>

                    <a href="" className="footer-link-a">
                        <h3 className='footer-link'>
                            Request a pickup
                        </h3>
                    </a>

                    <a href="https://navipickups.com/drivers/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Drive with us
                        </h3>
                    </a>

                    <a href="https://navipickups.com/partner-with-us/" className="footer-link-a">
                        <h3 className='footer-link'>
                            Partner with us
                        </h3>
                    </a>

                    <div className='footer-app-store-div'>
                        <a href="https://play.google.com/store/apps/details?id=com.navipickups" className="footer-app-store-btn">
                            <img src={Icons.google_play} className="footer-app-store-icon" />
                        </a>
                        <a href="https://apps.apple.com/ca/app/navi-pickups/id1587029478" className="footer-app-store-btn">
                            <img src={Icons.apple_store} className="footer-app-store-icon" />
                        </a>
                    </div>
    
                    <h3 className='footer-link-title'>
                        Download our app today!
                    </h3>

                </div>
            </div>

        </>
    )

}


export default Home